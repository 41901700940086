import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import clsx from 'clsx';
import ThreeBeesSign from './images/three-bees-front-sign.jpg';
import { useTracking } from './utilities/useTracking';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import PhoneIcon from './components/Icons/PhoneIcon';
import FacebookLogo from './components/Icons/FacebookLogo';
import InstagramLogo from './components/Icons/InstagramLogo';
import TwitterLogo from './components/Icons/TwitterLogo';
import ChowNow from './images/ChowNow.svg';
import UberEats from './images/UberEatsGreenPill.svg';
import Home from './pages/Home';
import Menu from './pages/Menu';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Kiosk from './pages/Kiosk';
import Styles from './App.module.scss';

export const contact: {
  phone: string;
  email: string;
  facebook: string;
  instagram: string;
  twitter: string;
} = {
  phone: '9137037229',
  email: 'contact@threebeeskck.com',
  facebook: 'https://www.facebook.com/ThreeBeesKCK',
  instagram: 'https://www.instagram.com/threebeeskck/',
  twitter: 'https://twitter.com/ThreeBeesKCK',
};

function isHoliday(): boolean {
  const currentDate = new Date().toJSON().slice(0, 10);
  const from = new Date('2024-12-23');
  const to = new Date('2024-12-31');
  const check = new Date(currentDate);
  return check > from && check < to;
}

const hours: { [day: string]: string } = {
  Monday: 'Closed',
  'Tuesday - Saturday': '8am - 6pm',
  Sunday: '8am - 2pm',
};

function App(): JSX.Element {
  useTracking('G-KNVWBY69QP');
  const location = useLocation();

  if (location.pathname === '/kiosk') {
    return (
      <Routes>
        <Route path="kiosk" element={<Kiosk />} />
      </Routes>
    );
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Three Bees KCK - Coffee, Tamales, and Pottery</title>
        <meta
          name="description"
          content="Three Bees Pottery and Coffee Shop serving local coffee, tamales, and imported Talavera pottery. Proudly located in the Rosedale neighborhood serving the Kansas City metro."
        />
      </Helmet>
      <div className={Styles.header_background}>
        <header className={Styles.wrapper}>
          <div className="row g-0">
            <div className="col">
              <Link to="/">
                <img
                  src={ThreeBeesSign}
                  alt="Three Bees Pottery &amp; Coffee Shop Sign"
                  className={Styles.sign}
                />
              </Link>
            </div>
          </div>
        </header>
      </div>
      <div className={Styles.footer_background}>
        <nav className={Styles.wrapper}>
          <Navigation />
        </nav>
      </div>
      <div className={`container-fluid ${Styles.wrapper}`}>
        <div className="row">
          <section className="col-xl-9 col-lg-8 col-md-6 mt-3 mb-md-3">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="menu" element={<Menu />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </section>
          <aside className="col-xl-3 col-lg-4 col-md-6 mt-md-0">
            <table className="mt-3">
              <tbody>
                {isHoliday() && (
                  <>
                    <tr
                      className={clsx({
                        [Styles.openDay]: isHoliday(),
                      })}
                    >
                      <td>Holiday Break</td>
                      <td>Closed</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        Dear Customers,
                        <br />
                        <br />
                        Three Bees Pottery and Coffee Shop will be closed for
                        the holidays so our team can spend time with their loved
                        ones.
                        <br />
                        We will resume our regular hours on{' '}
                        <b>Tuesday, December 31st</b>. Thank you for your
                        support and understanding.
                        <br />
                        Wishing you a joyful and warm holiday season!
                        <br />
                        <br />
                        Happy Holidays,
                        <br />
                        The Three Bees Pottery and Coffee Shop Team
                      </td>
                    </tr>
                  </>
                )}
                {Object.keys(hours).map((day, index) => {
                  const today = new Date().getDay();
                  const isMonday = today === 1 && index === 0;
                  const isOpen = today > 1 && index === 1;
                  const isSunday = today === 0 && index === 2;

                  return (
                    <tr
                      key={day}
                      className={clsx({
                        [Styles.isMonday]: isMonday && !isHoliday(),
                        [Styles.openDay]: isOpen && !isHoliday(),
                        [Styles.isSunday]: isSunday && !isHoliday(),
                      })}
                    >
                      <td>{day}</td>
                      <td>{hours[day]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <a
              className={`mt-3 btn btn-primary ${Styles.links}`}
              href={`tel:${contact.phone}`}
            >
              <PhoneIcon id="PhoneIcon" className="me-1" />
              (913) 703-7229
            </a>
            <a
              className={`mt-3 btn btn-primary ${Styles.links}`}
              href="https://www.google.com/maps/dir//Three+Bees+Pottery+And+Coffee+Shop,+925+Southwest+Blvd,+Kansas+City,+KS+66103/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              925 Southwest Blvd, Kansas City, KS 66103
            </a>
            <a
              className={`mt-3 btn btn-primary ${Styles.links}`}
              href="https://www.facebook.com/ThreeBeesKCK"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <FacebookLogo id="FacebookLogo" className="me-1" />
              ThreeBeesKCK
            </a>
            <a
              className={`mt-3 btn btn-primary ${Styles.links}`}
              href="https://www.instagram.com/threebeeskck/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <InstagramLogo id="InstagramLogo" className="me-1" />
              threebeeskck
            </a>
            <a
              className={`mt-3 btn btn-primary ${Styles.links}`}
              href="https://twitter.com/ThreeBeesKCK"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <TwitterLogo id="TwitterLogo" className="me-1" />
              ThreeBeesKCK
            </a>
            <h5 className="mt-4 mb-3 text-center">Order Online</h5>
            <a
              className={`mt-3 ${Styles.onlineOrdering}`}
              href="https://www.ubereats.com/store/three-bees-kck/I2c-J9HXVZqqcd1eChhRpQ"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <img src={UberEats} alt="Uber Eats logo for ordering online" />
            </a>
            <a
              className={`my-3 ${Styles.onlineOrdering}`}
              href="https://eat.chownow.com/order/34314/locations/51854"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <img src={ChowNow} alt="ChowNow logo for ordering online" />
            </a>
          </aside>
        </div>
      </div>
      <div className={Styles.footer_background}>
        <div className={`container-fluid ${Styles.wrapper}`}>
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
